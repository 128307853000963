<script setup lang="ts">
import GoogleLogo from '~/assets/img/google.svg';

withDefaults(
	defineProps<{
		isSignUp?: boolean;
	}>(),
	{
		isSignUp: false,
	}
);

const { $toast, $i18n } = useNuxtApp();
const supabase = useSupabaseClient();
const isHandlingGoogleSSO = ref(false);
const url = useRequestURL();

async function triggerSignInFlow() {
	isHandlingGoogleSSO.value = true;
	const { error } = await supabase.auth.signInWithOAuth({
		provider: 'google',
		options: {
			redirectTo: `${url.origin}/auth/confirm`,
		},
	});
	if (error) {
		isHandlingGoogleSSO.value = false;
		$toast.error($i18n.t('general.toast.error.default'));
		throw error;
	}
}
</script>

<template>
	<NuxtDivider :label="$t('auth.or')" />
	<NuxtButton
		color="white"
		variant="solid"
		block
		size="lg"
		trailing
		:loading="isHandlingGoogleSSO"
		:ui="{
			icon: {
				size: {
					lg: 'w-4 h-4',
				},
			},
		}"
		@click="triggerSignInFlow"
	>
		<img class="w-4 h-4" alt="google" :src="GoogleLogo" />
		<template v-if="isSignUp">
			{{ isHandlingGoogleSSO ? $t('auth.signup.googleSigningUp') : $t('auth.signup.googleSignUp') }}
		</template>
		<template v-else>
			{{ $t('auth.login.googleSignIn') }}
		</template>
	</NuxtButton>
</template>

<style scoped></style>
